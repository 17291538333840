// Load Bootstrap JS
import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

// App code
const navbar = `
    <nav id="upper-nav" class="nav-main navbar navbar-expand-sm navbar-dark bg-primary">
        <div class="container-fluid h-100">
            <ul class="navbar-nav w-100 h-100">
                <li class="nav-item">
                    <a class="nav-link" href="https://www.index-traub.com/en/imprint/">Imprint</a>
                </li>
                <li class="nav-item with-border">
                    <a class="nav-link" href="privacy.html">Privacy</a>
                </li>
                <li class="flex-grow-1"></li>
                <li class="nav-item">
                    <a class="nav-link external-link" href="https://www.index-traub.com">index-traub.com&nbsp;&nbsp;&nbsp;▸</a>
                </li>
                <li class="nav-item with-border">
                    <a class="nav-link external-link" href="https://ixshop.ixworld.com">iXshop&nbsp;&nbsp;&nbsp;▸</a>
                </li>
                <li class="nav-item with-border">
                    <a class="nav-link external-link" href="https://ix40.ixworld.com">iX4.0&nbsp;&nbsp;&nbsp;▸</a>
                </li>
            </ul>
        </div>
    </nav>
    <nav id="lower-nav" class="nav-main navbar navbar-expand-lg navbar-light">
        <div class="container-fluid h-100">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#nav-sub"
                aria-controls="nav-sub" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse h-100" id="nav-sub">
                <ul class="navbar-nav w-100 h-100">
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="index.html">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="getting_started.html">Getting started</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="api_reference.html">API reference</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="reference.html">Machine data reference</a>
                    </li>
                    <li class="flex-grow-1"></li>
                    <a class="navbar-brand d-flex" href="https://www.index-traub.com">
                        <img height="25px" src="images/logo_index.svg">
                    </a>
                </ul>
            </div>
        </div>
    </nav>
`;
document.body.insertAdjacentHTML("afterbegin", navbar);